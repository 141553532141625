.__cards {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px;

    .__card {
        display: block;
        transition: 0.8s;
        text-decoration: none;
        border-radius: 2px;
        opacity: 0.6;
        background-color: #000;
        color: #fff;
        border: none;
        cursor: pointer;
        text-align: left;
        color: #999999;
        width: 200px;
        height: 200px;
        text-align: center;

        .__logo {
            width: 100px;
            height: 100px;
            border-radius: 2px;
            display: grid;
            margin: 10px auto;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .__details {
            padding-left: 2%;

            p:nth-child(2) {
                opacity: 0.3;
                transition: 1s;
                color: #000;
            }
        }

        &:hover {
            background-color: #151515;
            opacity: 1;
            color: #fff;

            .__details>p:nth-child(2) {
                color: #999999;
                opacity: 0.6;
            }
        }
    }
}

@keyframes zoom-in {
    0% {
        transform: scale(0.4);
    }

    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 800px) {
    .__cards {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        .__card {
            padding: 2% 0;
            width: 180px;
            height: 180px;

            .__logo {
                width: 80px;
                height: 80px;
                border-radius: 2px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .__details {
                padding-left: 2%;

                h2 {
                    font-size: 1.25rem;
                }
            }
        }
    }
}


@media only screen and (max-width: 500px) {
    .__cards {
        grid-template-columns: 1fr;

        .__card {
            padding: 1% 0;
            width: 160px;
            height: 160px;

            .__details {
                padding-left: 2%;

                h2 {
                    font-size: 1.25rem;
                }

                p {
                    font-size: 0.75rem;
                }
            }
        }
    }
}