@import "colors.scss";

@font-face {
  font-family: "Sofia Sans Condensed";
  src: url("../assets/fonts/Sofia-Sans-Condensed/SofiaSansCondensed-Regular.ttf");
}

@font-face {
  font-family: "Sofia Sans Condensed Light";
  src: url("../assets/fonts/Sofia-Sans-Condensed/SofiaSansCondensed-Light.ttf");
}

@font-face {
  font-family: "Acherus Grotesque";
  src: url("../assets/fonts/acherus-grotesque.woff");
}

@font-face {
  font-family: "Poppins Black";
  src: url("../assets/fonts/poppins-black.ttf");
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
}

body {
  font-family: "Sofia Sans Condensed", Helvetica, Arial, sans-serif;
  color: $color-white;
  background-color: $color-black;
  cursor: crosshair;
}

a {
  color: $color-white;
}