.__navbar {
    font-family: "Poppins Black";
    width: 100vw;
    font-size: 1.5rem;
    padding: 30px 0;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  
    .__name {
      font-size: 1.5rem;
      padding: 0 20px;
      a{
        text-decoration: none;
      }
    }
  
    .__links {
      font-size: 1rem;
  
      li{
        display: inline-block;
        list-style: none;
  
        a {
          color: #fff;
          text-decoration: none;
          padding: 8px 10px;
          margin: 6px;
          transition: 1s;
          text-transform: uppercase;
    
          &:hover {
            opacity: 0.4;
          }
        }
      }
  
      .__lang{
        padding: 5px 6px;
        margin: 6px;
        text-transform: uppercase;
        border-radius: 50%;
        cursor: pointer;
        transition: 1s;
        border: none;
        font-family: "Poppins Black";
  
        &:hover {
          opacity: 0.4;
        }
      }
    }
  
    .__mobile_links{
      display: none;
  
      li{
        display: inline-block;
        list-style: none;
  
        a {
          text-decoration: none;
          padding: 2px;
          margin: 6px;
          transition: 1s;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 1rem;
          text-transform: uppercase;
  
          img{
            width: 25px;
            height: 25px;
          }
    
          &:hover {
            opacity: 0.4;
          }
        }
      }
  
      .__lang{
        padding: 5px 6px;
        margin: -5px 5px 0 5px;
        text-transform: uppercase;
        border-radius: 50%;
        cursor: pointer;
        transition: 1s;
        border: none;
        font-family: "Poppins Black";
        position: relative;
        top: -7px;
        
        &:hover {
          opacity: 0.4;
        }
      }
    }
  
    @media only screen and (max-width: 920px) {
      .__links {
        display: none;
      }
  
      .__mobile_links {
        display: block;
      }
    }
  }